import { zodResolver } from '@hookform/resolvers/zod'
import { setCookie } from 'cookies-next'
import { useRouter } from 'next/router'
import { signIn } from 'next-auth/react'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Button, Flex, Form, HStack, Link, PhoneInput, Text, TextDivider, useToast, VStack } from 'ui-lib'
import {
  getNumberOfEmployeesLimit,
  isDefined,
  NUMBER_OF_EMPLOYEES_LIMITS_OPTIONS,
  numberOfEmployeesLimits,
  passwordMaxChar,
  passwordMinChar,
  passwordRegex,
} from 'utils'
import { z } from 'zod'

import { Agreement, useRegisterUserMutation } from '@/gql'
import { KeepCookie } from '@/src/constants/cookies'
import { useRecaptcha } from '@/src/contexts/misc'
import { PHONE_REGEX } from '@/src/utils/application'
import { getRedirectUrl } from '@/src/utils/getRedirect'
import { analytics } from '@/src/utils/misc'

import ContinueWithGoogle from '../atoms/ContinueWithGoogle'

const schema = z.object({
  email: z.string({ required_error: 'Email is required' }).trim().email('Must be a valid email'),
  password: z
    .string({ required_error: 'Password is required' })
    .min(passwordMinChar, {
      message: `Password should have at least ${passwordMinChar} characters`,
    })
    .max(passwordMaxChar, {
      message: `Password should have at most ${passwordMaxChar} characters`,
    })
    .regex(passwordRegex, {
      message:
        'Password must include at least one uppercase letter, one lowercase letter, one digit, and one special character',
    }),
  businessName: z
    .string({ required_error: 'Business Name is required' })
    .trim()
    .min(1, { message: 'Business Name is required' }),
  numberOfEmployeesRange: z.object({
    min: z.number({ required_error: 'Number of employees is required' }),
    max: z.number().nullable(),
  }),
  firstName: z.string({ required_error: 'First Name is required' }).min(1, { message: 'First Name is required' }),
  lastName: z.string({ required_error: 'Last Name is required' }).min(1, { message: 'Last Name is required' }),
  phoneNumber: z.string().regex(PHONE_REGEX, { message: 'Invalid Phone Number' }).optional(),
})

type FormValues = z.infer<typeof schema>

const DEFAULT_VALUE: FormValues = {
  email: '',
  password: '',
  firstName: '',
  lastName: '',
  businessName: '',
  numberOfEmployeesRange: {
    min: 0,
    max: 0,
  },
  phoneNumber: '',
}

interface SignUpFormProps {
  hideGoogle: boolean
  isFastTrack: boolean
  referralCode?: string
  agreements: {
    platformAgreement: Agreement
    privacyPolicyAgreement: Agreement
    websiteTermsAgreement: Agreement
  }
}

export const SignUpForm = ({ isFastTrack, hideGoogle, referralCode, agreements }: SignUpFormProps) => {
  const { query, replace } = useRouter()

  const redirect = getRedirectUrl(query.redirect as string)

  const [status, setStatus] = useState('idle')

  const [registerUser] = useRegisterUserMutation()

  const toast = useToast()

  const { formState, register, handleSubmit, control, setValue } = useForm({
    defaultValues: DEFAULT_VALUE,
    resolver: zodResolver(schema),
  })
  const { execute: executeRecaptcha } = useRecaptcha()

  const isLoading = status === 'pending' || query.success === 'true'
  const isSubmitDisabled = !isDefined(executeRecaptcha)

  const showErrorToast = (description: string) => {
    toast({
      title: 'Sign up failed',
      description: description,
      position: 'top',
      status: 'error',
      duration: 3000,
      isClosable: true,
    })
  }

  const handleGoogleSignUp = async () => {
    analytics.track('Google sign up clicked', {
      team: 'Data Platform',
    })

    const token = await executeRecaptcha!('signup')
    if (isDefined(redirect)) {
      setCookie(KeepCookie.REDIRECTION_URL, redirect)
    }

    setCookie(KeepCookie.RECAPTCHA_TOKEN, token)
    setCookie(KeepCookie.AUTH_ACTION, 'sign-up')
    setCookie(KeepCookie.FAST_TRACK, isFastTrack)
    setCookie(KeepCookie.REFERRAL_CODE, referralCode)

    signIn('google')
  }

  const handleSignup = async (values: FormValues) => {
    setStatus('pending')
    const { email, password, businessName, firstName, lastName, phoneNumber, numberOfEmployeesRange } = values

    try {
      const token = await executeRecaptcha!('signup')
      await registerUser({
        variables: {
          body: {
            email,
            password,
            agreeTOS: true,
            agreePrivacyPolicy: true,
            fastTrack: isFastTrack,
            agreePlatformAgreement: true,
            referralCode,
            businessName,
            minNumberOfEmployees: numberOfEmployeesRange.min,
            maxNumberOfEmployees: numberOfEmployeesRange.max,
            firstName,
            lastName,
            phoneNumber,
            agreeMarketingCommunications: true,
          },
        },
      })

      if (isDefined(redirect)) {
        setCookie(KeepCookie.REDIRECTION_URL, redirect)
      }

      setCookie(KeepCookie.RECAPTCHA_TOKEN, token)
      setCookie(KeepCookie.AUTH_ACTION, 'sign-up')

      await signIn('credentials', {
        redirect: false,
        email,
        password,
      })
      replace(
        {
          pathname: '/sign-up',
          query: {
            success: true,
          },
        },
        undefined,
        {
          shallow: true,
        }
      ).then(() =>
        replace({
          pathname: '/',
          query,
        })
      )
    } catch (error) {
      setStatus('error')
      console.error('Sign up error: ', error)
      showErrorToast((error as { message?: string }).message ?? 'Please try again.')
    }
  }

  return (
    <Form onSubmit={handleSubmit(handleSignup)} id="login-form">
      <Flex direction={'column'}>
        {!hideGoogle && (
          <>
            <ContinueWithGoogle
              id="google-signup-button"
              border="1px solid"
              borderColor="border-secondary"
              isDisabled={isSubmitDisabled}
              onClick={handleGoogleSignUp}
            />

            <TextDivider content="OR" />
          </>
        )}
        <Flex direction="column" gap={6} w="full">
          <VStack gap={4}>
            <HStack alignItems={'start'} w="full">
              <Form.Field label="First Name" id="firstname-input" errorMessage={formState.errors.firstName?.message}>
                <Form.Input
                  autoComplete="given-name"
                  aria-autocomplete="inline"
                  placeholder={'Enter first name'}
                  type="text"
                  {...register('firstName')}
                />
              </Form.Field>
              <Form.Field label="Last Name" id="lastname-input" errorMessage={formState.errors.lastName?.message}>
                <Form.Input
                  autoComplete="family-name"
                  aria-autocomplete="inline"
                  type="text"
                  placeholder={'Enter last name'}
                  {...register('lastName')}
                />
              </Form.Field>
            </HStack>
            <Form.Field
              label="Legal Business Name"
              id="legal-business-name-input"
              errorMessage={formState.errors.businessName?.message}
            >
              <Form.Input
                autoComplete="organization"
                aria-autocomplete="inline"
                type="text"
                {...register('businessName')}
                placeholder="Enter business name"
              />
            </Form.Field>

            <Controller
              name="numberOfEmployeesRange"
              control={control}
              render={({ field: { value, ...field } }) => (
                <Form.Field
                  label="Number of employees"
                  id="numberOfEmployeesRange"
                  errorMessage={formState.errors.numberOfEmployeesRange?.min?.message}
                >
                  <Form.Select
                    {...field}
                    isClearable={false}
                    defaultValue={
                      isDefined(value)
                        ? NUMBER_OF_EMPLOYEES_LIMITS_OPTIONS.find(
                            (o) => o.value === getNumberOfEmployeesLimit(value.min).tier
                          )
                        : null
                    }
                    onChange={(option) => {
                      const limit = numberOfEmployeesLimits[option?.value!]
                      setValue('numberOfEmployeesRange', {
                        min: limit.min,
                        max: limit.max,
                      })
                    }}
                    options={NUMBER_OF_EMPLOYEES_LIMITS_OPTIONS}
                    placeholder="Select the number of employees"
                    name="numberOfEmployeesRange"
                  />
                </Form.Field>
              )}
            />

            <Controller
              name="phoneNumber"
              control={control}
              render={({ field }) => (
                <Form.Field
                  label="Personal phone number"
                  id="phoneNumber"
                  errorMessage={formState.errors.phoneNumber?.message}
                  helperText="We will send you a one time code via SMS to this number"
                >
                  <PhoneInput value={field.value} onChange={field.onChange} />
                </Form.Field>
              )}
            />

            <Form.Field label="Email" id="email-input" errorMessage={formState.errors.email?.message}>
              <Form.Input type="email" {...register('email')} placeholder="Enter email" />
            </Form.Field>
            <Form.Field
              label="Password"
              id="password-input"
              errorMessage={formState.errors.password?.message}
              helperText={
                <Text color="text-secondary" textStyle="paragraph-sm">
                  {`Minimum `}
                  <b>{`${passwordMinChar} characters`}</b>
                  {` at least `}
                  <b>{`one uppercase letter`}</b>
                  {', '}
                  <b>{`one lowercase letter`}</b>
                  {', '}
                  <b>{`one digit`}</b>
                  {', and '}
                  <b>{`one special character`}</b>
                </Text>
              }
            >
              <Form.PasswordInput
                isFormSubmitted={formState.isSubmitting}
                placeholder="Choose a password"
                maxLength={passwordMaxChar}
                autoComplete="new-password"
                aria-autocomplete="inline"
                {...register('password')}
              />
            </Form.Field>
          </VStack>
          <VStack gap={3}>
            <Button w="full" isLoading={isLoading} isDisabled={isSubmitDisabled} type="submit" size="lg">
              Sign Up
            </Button>
            <Text fontStyle="paragraph-md" color="text-secondary">
              By signing up, you agree to the Keep Technologies Corp&apos;s{' '}
              <Link
                isExternal
                href={agreements.websiteTermsAgreement.link}
                color="text-primary"
                id="terms-of-service-link"
                textDecoration="underline"
              >
                Terms of Use
              </Link>
              ,{' '}
              <Link
                isExternal
                href={agreements.privacyPolicyAgreement.link}
                color="text-primary"
                id="privacy-policy-link"
                textDecoration="underline"
              >
                Privacy Policy
              </Link>{' '}
              and{' '}
              <Link
                isExternal
                href={agreements.platformAgreement.link}
                color="text-primary"
                id="platform-agreement-link"
                textDecoration="underline"
              >
                Platform Agreement
              </Link>{' '}
              and to receive marketing messages at the entered number. You can unsubscribe at any time by replying STOP.
              Message and data rates apply.
            </Text>
          </VStack>
        </Flex>
      </Flex>
    </Form>
  )
}
