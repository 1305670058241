import { Alert } from './Alert'
import { Banner } from './Banner'
import { Button } from './Button'
import { Card } from './Card'
import { Checkbox } from './Checkbox'
import { ContainerWithTitleAndAction } from './ContainerWithTitleAndAction'
import { CustomSelect } from './CustomSelect'
import { DateField } from './DateField'
import { DateRangeInput } from './DateRangeInput'
import { Drawer } from './Drawer'
import { FieldContainer } from './FieldContainer'
import { FileInput } from './FileInput'
import { Flag } from './Flag'
import { FormControl } from './FormControl'
import { Header } from './Header'
import { Heading } from './Heading'
import { Input } from './Input'
import { List } from './List'
import { Modal } from './Modal'
import { Navbar } from './Navbar'
import { NumberInput } from './NumberInput'
import { Popover } from './Popover'
import { Progress } from './Progress'
import { Radio } from './Radio'
import { RedeemRewardsOption } from './RedeemRewardsOption'
import { Select } from './Select'
import { Sidebar } from './Sidebar'
import { Stepper } from './Stepper'
import { StyledInput } from './StyledInput'
import { Switch } from './Switch'
import { Table } from './Table'
import { Tabs } from './Tabs'
import { Tag } from './Tag'
import { Text } from './Text'
import { Textarea } from './Textarea'

export const components = {
  Alert,
  Banner,
  Button,
  Card,
  Checkbox,
  ContainerWithTitleAndAction,
  CustomSelect,
  DateField,
  DateRangeInput,
  Drawer,
  FieldContainer,
  FileInput,
  Flag,
  Form: FormControl,
  Heading,
  Header,
  Input,
  List,
  Modal,
  Navbar,
  NumberInput,
  Popover,
  Progress,
  Radio,
  RedeemRewardsOption,
  Select,
  Sidebar,
  Stepper,
  StyledInput,
  Switch,
  Table,
  Tabs,
  Tag,
  Text,
  Textarea,
}
