import getConfig from 'next/config'
import { isDefined } from 'utils'

import { initLogger } from '@/src/logging/logger'
import { initRum } from '@/src/monitoring/rum'
import { NextConfig } from '@/types/helpers'

import { useEffectOnce } from './use-effect-once'

const { publicRuntimeConfig } = getConfig() as NextConfig
const env = publicRuntimeConfig.keepEnv

export const useDatadog = () => {
  // Enabling DataDog logs for DEV and PROD envs.
  useEffectOnce(() => {
    if (isDefined(env)) {
      initLogger(env)
      initRum(env)
    }
  })
}
