import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { UserType } from 'db-schema'

import { ACCOUNTANT_PAGES, CARD_HOLDER_PAGES, PageName, PAGES, PageType } from '@/src/constants/pages'
import isDefined from '@/src/utils/dataShaping/isDefined'
import { legacyCheckHasRequiredFeatureFlags as checkHasRequiredFeatureFlags } from '@/src/utils/misc'
import { InternalUser } from '@/types/next-auth'

const checkHasRequiredAccessStatus = (user: InternalUser, page: PageType) => {
  if (!isDefined(page.accessStatus)) {
    return true
  }
  const ccAccessStatus = user?.business?.ccAccessStatus
  if (!isDefined(ccAccessStatus)) {
    return false
  }
  return page.accessStatus.includes(ccAccessStatus)
}

export const setSidebarPages = createAsyncThunk('sidebar/setSidebarPages', async (user: InternalUser) => {
  let sidebarPages = PAGES

  // Return pages based on user type
  if (user?.userType === UserType.CARDHOLDER) {
    sidebarPages = CARD_HOLDER_PAGES
  }

  if (user?.userType === UserType.ACCOUNTANT) {
    sidebarPages = ACCOUNTANT_PAGES
  }

  // Filter pages based on enabled feature flags
  const pages = await Promise.all(
    sidebarPages.map(async (page) => {
      const hasRequiredFeatureFlags = await checkHasRequiredFeatureFlags(user, page)
      const hasRequiredAccessStatus = checkHasRequiredAccessStatus(user, page)

      if (Boolean(hasRequiredFeatureFlags) && hasRequiredAccessStatus) {
        return page.page
      }
    })
  )

  const filteredPages = pages.filter((page) => page !== undefined) as PageName[]

  return filteredPages
})

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: {
    pages: [] as PageName[],
    ready: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setSidebarPages.fulfilled, (state, action) => {
      state.pages = action.payload
      state.ready = true
    })
  },
})

// export const {} = sidebarSlice.actions
export default sidebarSlice
