var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"toXvbkZORpRB3EJOwzz5e"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// @ts-check
// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import getConfig from 'next/config'

import { isKnownError } from './src/utils/sentry/known-errors'
import { NextConfig } from './types/helpers'

const { publicRuntimeConfig } = getConfig() as NextConfig

const env = publicRuntimeConfig.keepEnv
const isProd = env === 'prod'

Sentry.init({
  dsn: publicRuntimeConfig.sentryDsn,
  beforeSend: (event, hint) => {
    if (isKnownError(hint.originalException)) {
      return null
    }
    return event
  },
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0,
  environment: env,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
