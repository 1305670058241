import { zodResolver } from '@hookform/resolvers/zod'
import { setCookie } from 'cookies-next'
import { useRouter } from 'next/router'
import { signIn } from 'next-auth/react'
import { ParsedUrlQuery } from 'querystring'
import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Flex, Form, Link, TextDivider, useToast, VStack } from 'ui-lib'
import { isDefined } from 'utils'
import { z } from 'zod'

import ContinueWithGoogle from '@/src/components/atoms/ContinueWithGoogle'
import { KeepCookie } from '@/src/constants/cookies'
import { useRecaptcha } from '@/src/contexts/misc'
import useAttributionCookie from '@/src/hooks/useAttributionCookie'
import { AUTH_ERROR_CODES } from '@/src/utils/auth/constants'
import { getRedirectUrl } from '@/src/utils/getRedirect'

const schema = z.object({
  email: z.string({ required_error: 'Email is required' }).trim().email('Must be a valid email'),
  password: z.string({ required_error: 'Password is required' }).min(1, { message: 'Password is required' }),
})
type FormValues = z.infer<typeof schema>

const DEFAULT_VALUE = {
  email: '',
  password: '',
}

type LoginFormProps = {
  hideGoogle: boolean
}

export const LoginForm = ({ hideGoogle }: LoginFormProps) => {
  useAttributionCookie()
  const { push, query, replace } = useRouter()
  const toast = useToast()
  const { execute: executeRecaptcha } = useRecaptcha()
  const [status, setStatus] = useState('idle')

  const redirect = getRedirectUrl(query.redirect as string)
  const isLoginLoading = status === 'pending' || query.success === 'true'

  const { formState, register, handleSubmit } = useForm({
    defaultValues: DEFAULT_VALUE,
    resolver: zodResolver(schema),
  })

  const showErrorToast = useCallback(
    (code: string, description: string, qs: ParsedUrlQuery) => {
      const errorMapper: Record<string, string> = {
        OAuthAccountNotLinked: 'Please login using your password',
      }
      const error = errorMapper[description] ?? description

      let body: ReactNode = error
      if (code === AUTH_ERROR_CODES.ACCOUNT_DOESNT_EXIST) {
        body = (
          <>
            {error}
            <br />
            <Link
              textDecor="underline"
              onClick={() => {
                push({ pathname: 'sign-up', query: qs })
              }}
            >
              Please sign up first
            </Link>
          </>
        )
      }
      toast({
        title: 'Login failed',
        description: body,
        position: 'top',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    },
    [toast, push]
  )

  const handleGoogleSignIn = async () => {
    const token = await executeRecaptcha!('signin')

    setCookie(KeepCookie.RECAPTCHA_TOKEN, token)
    if (isDefined(redirect)) {
      setCookie(KeepCookie.REDIRECTION_URL, redirect)
    }

    setCookie(KeepCookie.AUTH_ACTION, 'sign-in')
    signIn('google')
  }

  const handleLogin = async (values: FormValues) => {
    setStatus('pending')

    const token = await executeRecaptcha!('signin')
    setCookie(KeepCookie.RECAPTCHA_TOKEN, token)
    setCookie(KeepCookie.AUTH_ACTION, 'sign-in')

    // use any type to stop the result being of type never
    if (isDefined(redirect)) {
      setCookie(KeepCookie.REDIRECTION_URL, redirect)
    }

    const res: any = await signIn('credentials', {
      ...values,
      redirect: false,
    })

    if (isDefined(res?.error)) {
      showErrorToast('', res?.error, query)
      setStatus('error')
    } else {
      if (Boolean(res.url.includes('/login/two-factor'))) {
        replace({
          pathname: res.url,
          query: {
            ...query,
          },
        })
        return
      }
      replace(
        {
          pathname: '/login',
          query: {
            success: true,
          },
        },
        undefined,
        {
          shallow: true,
        }
      ).then(() => replace('/'))
    }
  }

  useEffect(() => {
    const { error, code, ...rest } = query
    if (isDefined(error)) {
      showErrorToast(code as string, error as string, rest)
      replace({ pathname: 'login', query: rest })
    }
  }, [replace, query, showErrorToast])

  return (
    <Form onSubmit={handleSubmit(handleLogin)} id="login-form">
      <Flex direction={'column'}>
        {!hideGoogle && (
          <>
            <ContinueWithGoogle
              id="google-login-button"
              border="1px solid"
              borderColor="border-secondary"
              onClick={() => {
                handleGoogleSignIn()
              }}
              isDisabled={!isDefined(executeRecaptcha)}
            />

            <TextDivider content="OR" />
          </>
        )}
        <Flex direction="column" gap={6} w="full">
          <VStack gap={4}>
            <Form.Field label="Email" id="email-input" errorMessage={formState.errors.email?.message}>
              <Form.Input
                type="email"
                placeholder="Email"
                aria-autocomplete="inline"
                autoComplete="email"
                {...register('email')}
              />
            </Form.Field>
            <Form.Field label="Password" id="password-input" errorMessage={formState.errors.password?.message}>
              <Form.PasswordInput
                placeholder={'Password'}
                isFormSubmitted={formState.isSubmitting}
                autoComplete="current-password"
                aria-autocomplete="inline"
                {...register('password')}
              />
            </Form.Field>
          </VStack>
          <VStack gap={3}>
            <Button
              isLoading={isLoginLoading}
              type="submit"
              size="lg"
              w="full"
              data-testid="login-button"
              isDisabled={!isDefined(executeRecaptcha)}
            >
              Log In
            </Button>
            <Link
              id="forgot-password-link"
              onClick={() => {
                push('/account-recovery/forgot-password')
              }}
              textAlign="center"
              textStyle="title-sm"
              color="text-primary"
              mb={8}
              zIndex={1}
            >
              Forgot your password?
            </Link>
          </VStack>
        </Flex>
      </Flex>
    </Form>
  )
}
