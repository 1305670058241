import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file

import { Box, createStylesContext, InputLeftElement, useMultiStyleConfig } from '@chakra-ui/react'
import format from 'date-fns/format'
import React, { useState } from 'react'
import { DateRangePicker, DateRangePickerProps, RangeKeyDict } from 'react-date-range'
import { isDefined } from 'utils'
import { isValidDate } from 'utils/time'

import { Icon } from '../../icon'
import { Input } from '../input'

const [StylesProvider] = createStylesContext('DateRangeInput')

export type DateRange = {
  startDate: Date | string | undefined
  endDate: Date | string | undefined
}

export type DateInputRangeProps = Omit<DateRangePickerProps, 'ranges' | 'onChange'> &
  DateRange & {
    isDisabled?: boolean
    displayDefaultRanges?: boolean
    onChange: (dateRange: DateRange) => void
    variant?: 'default' | 'collapsible'
  }

const getVariant = (isDisabled: boolean) => {
  if (isDisabled) {
    return 'disabled'
  }
}

export const DateRangeInput = (props: DateInputRangeProps) => {
  const { variant, ...childProps } = props

  switch (variant) {
    case 'default':
      return <DefaultDateRangeInput {...childProps} />
    case 'collapsible':
      return <CollapsibleDateRangeInput {...childProps} />
    default:
      return <DefaultDateRangeInput {...childProps} />
  }
}

const DefaultDateRangeInput = ({
  displayDefaultRanges = false,
  isDisabled = false,
  direction = 'horizontal',
  months = 1,
  showMonthAndYearPickers = false,
  startDate: unformattedStartDate,
  endDate: unformattedEndDate,
  onChange,
  disabledDay,
  ...props
}: Omit<DateInputRangeProps, 'variant'>) => {
  const styles = useMultiStyleConfig('DateRangeInput', { variant: getVariant(isDisabled) })
  const startDate = isValidDate(unformattedStartDate) ? new Date(unformattedStartDate) : new Date()
  const endDate = isValidDate(unformattedEndDate) ? new Date(unformattedEndDate) : new Date()
  const ranges = [{ startDate, endDate, key: 'date-ranges' }]
  return (
    <StylesProvider value={styles}>
      <Box
        sx={{
          ...styles.box,
          ...(!displayDefaultRanges && {
            '& .rdrDefinedRangesWrapper': {
              display: 'none',
            },
          }),
          ...(months === 1 && {
            '& .rdrMonthName': {
              display: 'none',
            },
          }),
        }}
      >
        <DateRangePicker
          direction={direction}
          months={months}
          ranges={ranges}
          onChange={(date: RangeKeyDict) => {
            if (isDisabled) {
              return
            }
            const { startDate, endDate } = date['date-ranges']
            onChange({ startDate, endDate })
          }}
          disabledDay={isDisabled ? () => true : disabledDay}
          showMonthAndYearPickers={showMonthAndYearPickers}
          {...props}
        />
      </Box>
    </StylesProvider>
  )
}

const CollapsibleDateRangeInput = ({
  displayDefaultRanges = false,
  isDisabled = false,
  direction = 'horizontal',
  months = 1,
  showMonthAndYearPickers = false,
  startDate: unformattedStartDate,
  endDate: unformattedEndDate,
  onChange,
  disabledDay,
  ...props
}: Omit<DateInputRangeProps, 'variant'>) => {
  const styles = useMultiStyleConfig('DateRangeInput', { variant: getVariant(isDisabled) })
  const startDate = isValidDate(unformattedStartDate) ? new Date(unformattedStartDate) : new Date()
  const endDate = isValidDate(unformattedEndDate) ? new Date(unformattedEndDate) : new Date()
  const ranges = [{ startDate, endDate, key: 'date-ranges' }]
  const [isInputOpen, setIsInputOpen] = useState(false)

  const datePickerRef = React.useRef<HTMLDivElement>(null)

  const handleBlur = (e: React.FocusEvent) => {
    if (datePickerRef.current?.contains(e.relatedTarget as Node) === true || !isInputOpen) {
      return
    }
    setIsInputOpen(false)
  }

  // Close the date picker when clicking outside
  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isDefined(datePickerRef.current) && !datePickerRef.current.contains(event.target as Node)) {
        setIsInputOpen(false)
      }
    }

    if (isInputOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isInputOpen])

  return (
    <StylesProvider value={styles}>
      <Input
        leftElement={
          <InputLeftElement>
            <Icon icon="calendar" />
          </InputLeftElement>
        }
        onClick={isDisabled ? undefined : () => setIsInputOpen((prev) => !prev)}
        onBlur={isDisabled ? undefined : handleBlur}
        disabled={isDisabled}
        type="text"
        value={`${format(startDate, 'MMM dd, yyyy')} - ${format(endDate, 'MMM dd, yyyy')}`}
        readOnly={true}
      />
      <Box
        ref={datePickerRef}
        sx={{
          ...styles.box,
          display: isInputOpen ? 'flex' : 'none',
          marginX: '10px',
          borderRadius: '8px',
          border: '1px solid',
          borderColor: 'border-soft',
          width: 'fit-content',
          position: 'relative',
          zIndex: 10,
          ...(!displayDefaultRanges && {
            '& .rdrDefinedRangesWrapper': {
              display: 'none',
            },
          }),
          ...(months === 1 && {
            '& .rdrMonthName': {
              display: 'none',
            },
          }),
          '& .rdrDateDisplayWrapper': {
            display: 'none',
          },
          '& .rdrCalendarWrapper': {
            borderRadius: '8px',
            position: 'absolute',
            backgroundColor: 'white',
          },
        }}
      >
        <DateRangePicker
          direction={direction}
          months={months}
          ranges={ranges}
          onChange={(date: RangeKeyDict) => {
            if (isDisabled) {
              return
            }
            const { startDate, endDate } = date['date-ranges']
            onChange({ startDate, endDate })
            if (isDefined(endDate) && isDefined(startDate) && endDate > startDate) {
              setIsInputOpen(false)
            }
          }}
          disabledDay={isDisabled ? () => true : disabledDay}
          showMonthAndYearPickers={showMonthAndYearPickers}
          {...props}
        />
      </Box>
    </StylesProvider>
  )
}
