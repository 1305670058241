export const Navbar = {
  baseStyle: {
    container: {
      px: '4',
      alignItems: 'center',
      width: '100%',
      borderBottomWidth: '1px',
      borderColor: 'border-disabled',
      display: {
        base: 'block',
        lg: 'none',
      },
    },
  },
}
