import { PlusSquareIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  CloseButton,
  CloseButtonProps,
  createStylesContext,
  Flex,
  Text,
  TextProps,
  Tooltip,
  useDisclosure,
  useFormControlContext,
  useMultiStyleConfig,
  VStack,
} from '@chakra-ui/react'
import omit from 'lodash.omit'
import React, { MutableRefObject } from 'react'
import ReactSelect, {
  ClearIndicatorProps,
  components,
  ControlProps,
  CSSObjectWithLabel,
  DropdownIndicatorProps,
  InputProps,
  MenuListProps,
  OptionProps,
  PlaceholderProps,
  SelectInstance,
  SingleValueProps,
} from 'react-select'
import AsyncReactSelect from 'react-select/async'
import { isDefined } from 'utils'

const [StylesProvider, useStyles] = createStylesContext('CustomSelect')

type CustomProps<Option extends EnhancedOption> = {
  selectRef?: MutableRefObject<SelectInstance<null> | null>
  onCreate?: () => void
  createLabel?: string
  optionComponent?: (props: { option: Option }) => JSX.Element
  valueComponent?: (props: { option: Option }) => JSX.Element
  dropdownIndicatorComponent?: () => JSX.Element | null
  id?: string
  isInvalid?: boolean
}

type CommonProps =
  | 'onChange'
  | 'components'
  | 'isDisabled'
  | 'placeholder'
  | 'isClearable'
  | 'defaultMenuIsOpen'
  | 'closeMenuOnSelect'
  | 'onMenuClose'
  | 'value'
  | 'defaultValue'
  | 'isSearchable'
  | 'onBlur'
  | 'name'
  | 'menuPlacement'
  | 'inputValue'
  | 'onInputChange'
  | 'className'
  | 'isLoading'
  | 'ref'
  | 'menuPortalTarget'

type EnhancedOption = {
  [key: string]: any
  tooltip?: string
}

type SelectProps<Option extends EnhancedOption, IsMulti extends boolean> = Pick<
  React.ComponentProps<typeof ReactSelect<Option, IsMulti>>,
  CommonProps | 'options' | 'isMulti' | 'noOptionsMessage'
> &
  CustomProps<Option>

/**
 * @property {getOptionValue}  AsyncSelectProps.getOptionValue - GetOptionValue value is necessary to be passed when the loadOptions return an array of options that differs from the common {value,label} object See this [thread](https://github.com/JedWatson/react-select/issues/5473#issuecomment-1452084272). If not passed, it may cause the select to not work properly for isMulti select.
 */
type AsyncSelectProps<Option extends EnhancedOption, IsMulti extends boolean> = Pick<
  React.ComponentProps<typeof AsyncReactSelect<Option, IsMulti>>,
  CommonProps | 'loadOptions' | 'defaultOptions' | 'isMulti' | 'getOptionValue'
> &
  CustomProps<Option>

const CustomControl = <Option extends EnhancedOption, IsMulti extends boolean = false>({
  children,
  innerProps,
  innerRef,
}: ControlProps<Option, IsMulti>) => {
  const styles = useStyles()

  return (
    <Flex sx={styles.control} tabIndex={0} ref={innerRef} {...innerProps}>
      {children}
    </Flex>
  )
}

const CustomMenuList = <Option extends EnhancedOption, IsMulti extends boolean = false>({
  children,
  selectProps,
  innerProps,
}: MenuListProps<Option, IsMulti>) => {
  const { onCreate, createLabel, onMenuClose } = selectProps as SelectProps<Option, IsMulti>
  const styles = useStyles()

  const handleCreate = () => {
    onCreate?.()
    onMenuClose?.()
  }

  return (
    <Box sx={styles.menu} {...innerProps} overflowY="auto" maxHeight="360px">
      <VStack width="full" spacing="1" padding="2">
        {isDefined(onCreate) && isDefined(createLabel) && (
          <Button
            sx={styles.addOptionButton}
            variant="ghost"
            leftIcon={<PlusSquareIcon />}
            aria-label={createLabel}
            onClick={handleCreate}
          >
            {createLabel}
          </Button>
        )}
        {children}
      </VStack>
    </Box>
  )
}

const CustomClearIndicator = <Option extends EnhancedOption, IsMulti extends boolean = false>({
  innerProps,
}: ClearIndicatorProps<Option, IsMulti>) => {
  return <CloseButton size="sm" {...(innerProps as CloseButtonProps)} />
}

const CustomSingleValue = <Option extends EnhancedOption, IsMulti extends boolean = false>({
  children,
  ...rest
}: SingleValueProps<Option, IsMulti>) => {
  const { optionComponent, valueComponent } = rest.selectProps as SelectProps<Option, IsMulti>

  const componentToRender = isDefined(valueComponent) ? valueComponent : optionComponent

  return (
    <components.SingleValue {...rest}>
      {isDefined(componentToRender) ? (
        componentToRender({
          option: rest.data,
        })
      ) : (
        <Text color={rest.isDisabled ? 'text-disabled' : 'text-primary'} textStyle="title-sm">
          {children}
        </Text>
      )}
    </components.SingleValue>
  )
}

const CustomPlaceholder = <Option, IsMulti extends boolean = false>({
  innerProps,
  selectProps,
}: PlaceholderProps<Option, IsMulti>) => {
  const styles = useStyles()

  return (
    <Text {...(innerProps as TextProps)} sx={styles.placeholder}>
      {selectProps.placeholder}
    </Text>
  )
}

const CustomOption = <Option extends { tooltip?: string }, IsMulti extends boolean = false>({
  children,
  innerProps,
  data,
  isDisabled,
  ...rest
}: OptionProps<Option, IsMulti>) => {
  const { optionComponent } = rest.selectProps as SelectProps<Option, IsMulti>
  const styles = useStyles()

  const tooltip = data.tooltip as string | undefined
  let component = (
    <Text color={isDisabled ? 'text-disabled' : 'text-primary'} textStyle="title-sm">
      {children}
    </Text>
  )
  if (isDefined(optionComponent)) {
    component = optionComponent({
      option: data,
    })
  }

  if (isDefined(tooltip)) {
    component = <Tooltip label={tooltip}>{component}</Tooltip>
  }

  return (
    <Box
      {...innerProps}
      sx={{
        ...styles.customOption,
        bg: rest.isFocused ? 'bg-neutral' : 'bg-primary',
        _hover: {
          bg: isDisabled ? 'transparent' : 'bg-neutral',
          cursor: isDisabled ? 'not-allowed' : 'pointer',
        },
      }}
    >
      {component}
    </Box>
  )
}

const CustomInput = <Option, IsMulti extends boolean = false>(props: InputProps<Option, IsMulti>) => (
  <components.Input {...props} isHidden={false} autoComplete="no-thanks" />
)

const CustomDropdownIndicator = <Option extends EnhancedOption, IsMulti extends boolean = false>(
  props: DropdownIndicatorProps<Option, IsMulti>
) => {
  const { dropdownIndicatorComponent } = props.selectProps as SelectProps<Option, IsMulti>
  if (isDefined(dropdownIndicatorComponent)) {
    return dropdownIndicatorComponent()
  }

  return <components.DropdownIndicator {...props} />
}

const defaultStyles = {
  control: () => ({}),
  indicatorSeparator: () => ({}),
  menu: (styles: Record<string, unknown>) =>
    omit(styles, ['backgroundColor', 'borderRadius', 'boxShadow']) as CSSObjectWithLabel,
}

const useSelectCommonProps = <Option extends EnhancedOption, IsMulti extends boolean = false>({
  isSearchable = true,
  isClearable = true,
  isDisabled = false,
  ...props
}: SelectProps<Option, IsMulti>) => {
  const { isInvalid = false, id: formContextId } = useFormControlContext() ?? {}
  const id = formContextId ?? props.id

  const { isOpen: isFocused, onOpen: onFocus, onClose: onBlur } = useDisclosure()

  const getVariant = () => {
    if (isFocused) {
      return 'focused'
    }

    if (isDisabled) {
      return 'disabled'
    }

    if (isInvalid) {
      return 'invalid'
    }

    return 'default'
  }

  const styles = useMultiStyleConfig('CustomSelect', {
    variant: getVariant(),
  })

  const getStylesProviderProps = () => {
    return {
      value: styles,
    }
  }

  const getSelectProps = () => {
    return {
      ...props,
      inputId: id,
      isClearable: isClearable,
      isSearchable: isSearchable,
      isDisabled: isDisabled,
      'aria-invalid': isInvalid,
      onFocus,
      onBlur,
      components: {
        Control: CustomControl,
        ClearIndicator: CustomClearIndicator,
        MenuList: CustomMenuList,
        Placeholder: CustomPlaceholder,
        SingleValue: CustomSingleValue,
        Option: CustomOption,
        Input: CustomInput,
        DropdownIndicator: CustomDropdownIndicator,
        ...props.components,
      },
      styles: defaultStyles,
    }
  }

  return {
    getStylesProviderProps,
    getSelectProps,
  }
}

export const Select = React.forwardRef(
  <Option extends EnhancedOption, IsMulti extends boolean = false>(
    props: SelectProps<Option, IsMulti>,
    ref: React.Ref<any>
  ) => {
    const { getSelectProps, getStylesProviderProps } = useSelectCommonProps(props)

    return (
      <StylesProvider {...getStylesProviderProps()}>
        <ReactSelect ref={ref} {...getSelectProps()} />
      </StylesProvider>
    )
  }
) as <Option extends EnhancedOption, IsMulti extends boolean = false>(
  props: SelectProps<Option, IsMulti> & React.RefAttributes<any>
) => JSX.Element

export const AsyncSelect = React.forwardRef(
  <Option extends EnhancedOption, IsMulti extends boolean = false>(
    props: AsyncSelectProps<Option, IsMulti>,
    ref: React.Ref<any>
  ) => {
    const { getSelectProps, getStylesProviderProps } = useSelectCommonProps(props)

    return (
      <StylesProvider {...getStylesProviderProps()}>
        <AsyncReactSelect ref={ref} {...getSelectProps()} />
      </StylesProvider>
    )
  }
) as <Option extends EnhancedOption, IsMulti extends boolean = false>(
  props: AsyncSelectProps<Option, IsMulti> & React.RefAttributes<any>
) => JSX.Element
