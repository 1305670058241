import '@datadog/browser-logs/bundle/datadog-logs'

import { datadogRum } from '@datadog/browser-rum'
import getConfig from 'next/config'

import { NextConfig } from '@/types/helpers'

const { publicRuntimeConfig } = getConfig() as NextConfig

export const initRum = (env?: string) => {
  datadogRum.init({
    applicationId: publicRuntimeConfig.datadogRum.applicationId,
    clientToken: publicRuntimeConfig.datadogRum.clientToken,
    site: publicRuntimeConfig.dataDogSite,
    service: 'web',
    env,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: publicRuntimeConfig.datadogRum.sampleRate,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  })
}
